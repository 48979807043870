import * as React from 'react'

import MainLayout from '@/layouts'
import { ButtonPrimmary } from '@/uikit'

const ThemePage: React.FC = () => {
  return (
    <MainLayout>
      <h1>Theme</h1>
      <ButtonPrimmary>elo</ButtonPrimmary>
      <h2>Test colors 2</h2>
      <h3>Test colors</h3>
      <p className="text-blue">Blue</p>
      <p className="text-blue-light">Blue light</p>
      <p className="text-blue-dark">Blue dark</p>
      <p className="text-text">Text default</p>
      <p className="text-text-70">Text 70%</p>
      <p className="text-text-70-dm bg-body-dm">Text 70% (dark mode)</p>
      <p className="text-text-50">Text 50%</p>
      <p className="text-text-50-dm bg-body-dm">Text 50% (dark mode)</p>
      <p className="text-orange">Text orange</p>
      <p className="text-orange-darker">Text orange darker</p>
      <p className="text-white bg-dark">Text white</p>
      <p className="text-red">Text red</p>

      <h3>Backgrounds</h3>
      <p className="text-text-70-dm bg-body-dm mb-2">Dark: Body</p>
      <p className="text-text-70-dm bg-body-bg1-dm mb-2">Dark: Background 1</p>
      <p className="text-text-70-dm bg-body-bg2-dm mb-2">Dark: Background 2</p>
      <p className="text-text-70 bg-body mt-2 mb-2">Light: Body</p>
      <p className="text-text-70 bg-body-bg1 mb-2">Light: Background 1</p>
      <p className="text-text-70 bg-body-bg2 mb-2">Light: Background 2</p>

      <a href="/">Hello</a>
    </MainLayout>
  )
}

export default ThemePage
